import styles from "../../components/home/price/price.module.scss";

type NumberCallback = (n: number) => any;

export const TarifScreen = ({
  tarif,
  changeTarif,
}: {
  tarif?: number;
  changeTarif?: NumberCallback;
}) => {
  const sendOrder = (order: number) => {
    if (changeTarif) changeTarif(order);
  };

  const buttonClassName = (active: boolean) => {
    let cn = `${styles.price__item_button} ${styles.price__item_button_select}`;
    if (active) cn += ` ${styles.price__item_button_active}`;

    return cn;
  };

  return (
    <>
      <div className={styles.price__list}>
        <div className={styles.price__item}>
          <div className={styles.price__item_header}>Базовый</div>

          <div className={styles.price__item_subheader}>
            Подойдет частным мастерам
          </div>

          <ul className={styles.price__item_list}>
            <li>Перечень услуг</li>
            <li>Запись клиентов</li>
            <li>Рассылка новостей</li>
            <li>Напоминания для клиентов</li>
          </ul>

          <div className={styles.price__item_border}>
            <div className={styles.price__item_discount}>-52%</div>
            <div className={styles.price__item_price}>
              470{" "}
              <b>
                <span>₽</span>/мес
              </b>
            </div>
            <div className={styles.price__item_oldprice}>900 Р</div>

            <img
              src="/images/coffee2.svg"
              className={styles.price__item_coffe}
            />
          </div>

          <div
            onClick={() => sendOrder(1)}
            className={buttonClassName(tarif === 1)}
          >
            {tarif == 1 ? "Выбран" : "Выбрать"}
          </div>
        </div>

        <div className={styles.price__item}>
          <div className={styles.price__item_header}>Расширеный</div>

          <div className={styles.price__item_subheader}>
            Подойдет салонам и студиям
          </div>

          <ul className={styles.price__item_list}>
            <li>Перечень услуг</li>
            <li>Запись клиентов</li>
            <li>Рассылка новостей</li>
            <li>Напоминания для клиентов</li>
            <li className={styles.price__item_list_mt}>
              Больше мастеров в одном боте
            </li>
            <li>Выбор нескольких салонов</li>
          </ul>

          <div className={styles.price__item_border}>
            <div className={styles.price__item_discount}>-58%</div>
            <div className={styles.price__item_price}>
              870{" "}
              <b>
                <span>₽</span>/мес
              </b>
            </div>
            <div className={styles.price__item_oldprice}>1500 Р</div>

            <img
              src="/images/coffee3.svg"
              className={styles.price__item_coffe}
            />
          </div>

          <div
            onClick={() => sendOrder(2)}
            className={buttonClassName(tarif === 2)}
          >
            {tarif == 2 ? "Выбран" : "Выбрать"}
          </div>
        </div>
      </div>
    </>
  );
};
