"use client";
import {
  ArchiveTray,
  List,
  ShoppingCart,
  SignIn,
  SquaresFour,
  Users,
} from "phosphor-react";
import { Avatar, Button, Card, Divider, Sidebar } from "keep-react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { user } from "../../store/user";

export const DUserMenu = observer(({ className }: { className?: string }) => {
  return (
    <div className={className}>
      <Card className="max-w-full flex justify-between items-center p-2 px-4 gap-2">
        <Link to="/dashboard">
          <img src="/images/text-logo.svg" />
        </Link>
        <div className="grow" />
        <div className="flex items-center gap-2">
          <div>
            <Avatar shape="circle" img={user.currentUser?.AvatarPath || ""} />
          </div>
          <div className="hidden md:block">
            <p className="mb-0 text-body-3 font-medium text-metal-600">
              {`${user.currentUser?.FirstName} ${user.currentUser?.LastName}`}
            </p>
            <p className="text-body-4 font-normal text-metal-400">
              @{user.currentUser?.Nickname}
            </p>
          </div>
        </div>
        <Button variant="link" size="xs">
          <SignIn size={36} />
        </Button>
      </Card>
    </div>
  );
});
