import { axiosInstance } from "./axiosInstance";

type NewBot = {
  type: number;
  templateID: number;
  token: string;
};

export const getMyBot = () => {
  return axiosInstance.get("/bot");
};

export const createBot = (newBotData: NewBot) => {
  return axiosInstance.post("/bot", newBotData);
};
