"use client";

import styles from "./main_screen.module.scss";

import { LoginButton } from "@telegram-auth/react";
import { useNavigate } from "react-router-dom";
import { SignIn } from "../../../api/auth";
import { useEffect, useState } from "react";
import { Button } from "keep-react";

export const MainScreen = () => {
  const [login, setLogin] = useState(false);
  const navigate = useNavigate();

  const menuItems = [
    { name: "Возможности", path: "#possibilities" },
    { name: "О нас", path: "#moreinfo" },
    { name: "Продукт", path: "#feature" },
    { name: "Цены", path: "#price" },
    { name: "Контакты", path: "#contact" },
  ];

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setLogin(true);
    }
  });

  const handleTelegramResponse = async (response: any) => {
    localStorage.setItem("tgdata", JSON.stringify(response));
    console.log(response);
    const newData: any = {};
    for (let key of Object.keys(response)) {
      newData[key] = `${response[key]}`;
    }

    console.log(newData);
    const auth: any = await SignIn(newData);

    if (auth.access_token) {
      localStorage.setItem("token", auth.access_token);
      navigate("/dashboard");
    }
  };

  // @ts-ignore
  return (
    <div className={styles.main_screen__wrapper}>
      <div className={styles.main_screen}>
        <div className={styles.nav}>
          <img src="/images/text-logo.svg" className={styles.logo} />

          {menuItems.map((item, index) => (
            <a key={index} href={item.path} className={styles.item}>
              {item.name}
            </a>
          ))}

          {login && (
            <Button
              variant="outline"
              size="xs"
              onClick={() => navigate("/dashboard")}
            >
              Личный кабинет
            </Button>
          )}
          {login !== true && (
            <div className={styles.button}>
              <LoginButton
                botUsername="dailyru_bot"
                buttonSize="large"
                cornerRadius={20}
                lang="ru"
                showAvatar={true}
                onAuthCallback={handleTelegramResponse}
              />
            </div>
          )}
        </div>

        <img className={styles["hello-daily"]} src="/images/hello-daily.png" />

        <div className={styles.info}>
          <div className={styles.header}>
            <img src="/images/text-logo.svg" /> - это телеграм-бот для работы с
            Вашими клиентами
          </div>
          <div className={styles.header_mobile}>
            <img src="/images/text-logo.svg" />
            <div>Это телеграм-бот для работы с Вашими клиентами</div>
          </div>

          <div className={styles.description}>
            Он может показать Ваши услуги, записать клиента, уведомить его о
            скорой записи, уточнить актуальность записи, рассылать Ваши акции.
          </div>

          <a href="https://t.me/dailyru_demo_bot" className={styles.linkButton}>
            Посмотреть демо-бот
          </a>
        </div>

        <div className={styles.preview}>
          <img src="/images/main.png" />
          <img className={styles.arrow} src="/images/arrow.svg" />
        </div>
      </div>
    </div>
  );
};
