import { createBrowserRouter } from "react-router-dom";
import { AddBotPage } from "./pages/addbot/addbot";
import { DashboardPage } from "./pages/dashboard/dashboard";
import { MainPage } from "./pages/main";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <MainPage />,
  },
  {
    path: "/dashboard",
    element: <DashboardPage />,
  },
  {
    path: "/bot/add",
    element: <AddBotPage />,
  },
]);
