import styles from "./possibilities.module.scss";

export const Possibilites = () => {
    const items = [
        {
            icon: "/images/icon1.png",
            name: "Список услуг",
            description:
                "Познакомьте Ваших клиентов с видами ваших услуг уже с первой страницы.",
        },
        {
            icon: "/images/icon2.png",
            name: "Умные напоминания",
            description:
                "Бот позаботится о Вашем клиенте и за час напомнит ему о запланированной встрече.",
        },
        {
            icon: "/images/icon3.png",
            name: "Ничего лишнего",
            description:
                "Не нужно скачивать приложение - бот позволяет записываться на услугу прямо в Telegram",
        },
        {
            icon: "/images/icon4.png",
            name: "Акции, новости, скидки",
            description:
                "Рассказывайте о Ваших выгодных предложениях с помощью функции рассылки",
        },
    ];
    return (
        <div className={styles.possibilities} id="possibilities">
            <div className={styles.header}>Возможности</div>

            <div className={styles.description}>
                Узнайте, что умеет <img src="/images/mini-logo-text.svg" />, как его
                можно использовать и какие задачи он помогает решать. <br />
                Поднимите сервис на новый уровень с ботом-помощником <b>Дейли</b>.
            </div>

            <div className={styles.item__wrapper}>
                {items.map((item, index) => (
                    <div key={index} className={styles.item}>
                        <img className={styles.item__icon} src={item.icon} />

                        <div className={styles.item__info}>
                            <div className={styles.item__header}>{item.name}</div>

                            <div className={styles.item__description}>{item.description}</div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};
