export const InstructionScreen = () => {
  return (
    <div className="flex-col">
      <p className="text-gray-950 text-base">
        В Telegram боты создаются с помощью специального бота{" "}
        <a href="https://t.me/botfather" className="text-primary">
          @BotFather
        </a>
        . Через него можно управлять данными бота, добавить описание, аватар и
        т.д.
      </p>
      <br />
      <p className="text-gray-950 text-base">
        Создадим бота и придумаем ему название:
        <br />
        <br />
        1. Откройте в Telegram бота{" "}
        <a href="https://t.me/botfather" className="text-primary">
          @BotFather
        </a>
        .
        <br />
        2. Напишите ему /newbot.
        <br />
        3. Придумайте и напишите название бота. Оно будет отображаться в
        контактах и чатах. Например, «Мастер маникюра».
        <br />
        4. Придумайте и напишите юзернейм. Он используется для упоминания бота и
        в ссылках. Юзернейм должен быть на латинице и обязательно заканчиваться
        на «bot». Например, «nails_master_bot».
      </p>
    </div>
  );
};
