import { Input, Label } from "keep-react";

export const SecondInstructionScreen = ({
  token,
  changeToken,
}: {
  token: string;
  changeToken: (token: string) => void;
}) => {
  return (
    <div className="flex flex-col items-center max-w-full">
      <p className="text-gray-950 text-base max-w-full break-words">
        В ответ бот отаправит Вам сообщение с информацией и токеном бота, пример
        можно посмотреть на изображении
        (5727341744:AAEXtJQLxAiEeE751QTBHnY_iUQbmG2C7VQ)
      </p>
      <img
        className="w-[300px] mt-3"
        src="https://leonardo.osnova.io/81c85744-9c30-568d-b86e-83876d62a40f/-/preview/500/-/format/webp/"
      />
      <br />
      <p className="text-gray-950 text-base">
        Вставьте полученый Вами токен в поле ниже:
      </p>
      <fieldset className="w-full space-y-1">
        <Label htmlFor="token">Токен телеграм бота</Label>
        <Input
          id="token"
          placeholder="Введите токен"
          type="text"
          className="bg-white"
          value={token}
          onChange={(e) => changeToken(e.target.value)}
        />
      </fieldset>
    </div>
  );
};
