import { Button, Card, Empty } from "keep-react";
import { Plus } from "phosphor-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getMyBot } from "../../api/bot";
import { DashboardLayout } from "../../layout/dashboard/dashboard";

export const DashboardPage = () => {
  const [botList, setBotList] = useState([]);
  const navigate = useNavigate();

  const getUserBot = async () => {
    const bots: any = await getMyBot();
    setBotList(bots);
  };

  useEffect(() => {
    getUserBot();
  }, []);

  const addBot = () => {
    console.log("redirect");
    navigate("/bot/add");
  };

  return (
    <DashboardLayout>
      <Card className="max-w-full p-3">
        <div className="flex flex-row justify-between max-w-full items-center">
          <h3 className="text-2xl font-semibold">Мои боты</h3>

          <Button
            color="primary"
            variant="outline"
            className="items-center flex flex-row"
            size="xs"
            onClick={addBot}
          >
            <Plus
              className="mr-2 mt-[-2px] text-primary"
              size={20}
              weight="bold"
            />{" "}
            Добавить бота
          </Button>
        </div>

        {botList.length === 0 && (
          <Empty>
            <Empty.Image>
              <img
                src="https://staticmania.cdn.prismic.io/staticmania/16994ca5-ac01-4868-8ade-1b9e276ccdb3_Property+1%3DFolder_+Property+2%3DLg.svg"
                height={234}
                width={350}
                alt="404"
              />
            </Empty.Image>
            <Empty.Title>У Вас нет еще не одного бота</Empty.Title>
            <Empty.Description>
              Как только Вы добавите своего первого бота, он сразу появится тут,
              и у Вас будет возможность управлять им, а так же смотреть Ваши
              заявки и статистику
            </Empty.Description>
            <Button variant="outline" onClick={addBot}>
              Добавить моего первого бота
            </Button>
          </Empty>
        )}

        {botList.length > 0 && (
          <div className="flex flex-row flex-wrap gap-3">
            {botList.map((b: any) => (
              <div key={b.ID}>{b.ID}</div>
            ))}
          </div>
        )}
      </Card>
    </DashboardLayout>
  );
};
