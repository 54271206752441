"use client";
import axios from "axios";
import styles from "./price.module.scss";
import { Store } from "react-notifications-component";
import { Alert, AlertTitle, Snackbar } from "@mui/material";
import { useState } from "react";

export const Price = () => {
  const [open, setOpen] = useState(false);
  const [openError, setOpenError] = useState(false);
  const [openError2, setOpenError2] = useState(false);
  const [errorData, setErrorData] = useState(false);

  const sendOrder = async (order: string) => {
    const tgData = localStorage.getItem("tgdata");

    if (tgData) {
      const result = await axios.post("https://dailybot.ru/users/order", {
        ...JSON.parse(tgData),
        action: order,
      });
      setOpen(true);
    } else {
      setOpenError(true);
    }
  };
  return (
    <div className={styles.price__wrapper} id="price">
      <div className={styles.price}>
        <div className={styles.header}>Daily-стоимость</div>
        <div className={styles.description}>
          <img src="/images/mini-logo-text.svg" />
          может оценить экономию времени для Вас и удобство пользования для
          Ваших клиентов в чашках кофе!
        </div>

        <div className={styles.price__list}>
          <div className={styles.price__item}>
            <div className={styles.price__item_header}>Базовый</div>

            <div className={styles.price__item_subheader}>
              Подойдет частным мастерам
            </div>

            <ul className={styles.price__item_list}>
              <li>Перечень услуг</li>
              <li>Запись клиентов</li>
              <li>Рассылка новостей</li>
              <li>Напоминания для клиентов</li>
            </ul>

            <div className={styles.price__item_border}>
              <div className={styles.price__item_discount}>-52%</div>
              <div className={styles.price__item_price}>
                470{" "}
                <b>
                  <span>₽</span>/мес
                </b>
              </div>
              <div className={styles.price__item_oldprice}>900 Р</div>

              <img
                src="/images/coffee2.svg"
                className={styles.price__item_coffe}
              />
            </div>

            {/* <div */}
            {/*     onClick={() => sendOrder("order_1")} */}
            {/*     className={styles.price__item_button} */}
            {/* > */}
            {/*     Попробовать <br /> 14 дней за 1 рубль */}
            {/* </div> */}
          </div>

          <img className={styles.arrow} src="/images/arrow.svg" />

          <div className={styles.price__item}>
            <div className={styles.price__item_header}>Расширеный</div>

            <div className={styles.price__item_subheader}>
              Подойдет салонам и студиям
            </div>

            <ul className={styles.price__item_list}>
              <li>Перечень услуг</li>
              <li>Запись клиентов</li>
              <li>Рассылка новостей</li>
              <li>Напоминания для клиентов</li>
              <li className={styles.price__item_list_mt}>
                Больше мастеров в одном боте
              </li>
              <li>Выбор нескольких салонов</li>
            </ul>

            <div className={styles.price__item_border}>
              <div className={styles.price__item_discount}>-58%</div>
              <div className={styles.price__item_price}>
                870{" "}
                <b>
                  <span>₽</span>/мес
                </b>
              </div>
              <div className={styles.price__item_oldprice}>1500 Р</div>

              <img
                src="/images/coffee3.svg"
                className={styles.price__item_coffe}
              />
            </div>

            {/* <div */}
            {/*     onClick={() => sendOrder("order_2")} */}
            {/*     className={styles.price__item_button} */}
            {/* > */}
            {/*     Оставить заявку <br /> */}
            {/*     на подключение */}
            {/* </div> */}
          </div>
        </div>
      </div>

      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
      >
        <Alert
          onClose={() => setOpen(false)}
          severity="success"
          sx={{ width: "100%" }}
        >
          <AlertTitle>Успешно</AlertTitle>
          Ваша заявка принята,мы свяжемся с Ваами в ближайшее время
        </Alert>
      </Snackbar>
      <Snackbar
        open={openError}
        autoHideDuration={6000}
        onClose={() => setOpenError(false)}
      >
        <Alert
          onClose={() => setOpenError(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          <AlertTitle>Ошибка авторизации</AlertTitle>
          Вам необходимо войти через Телеграм в верхней части сайта, и повторить
          снова
        </Alert>
      </Snackbar>
      <Snackbar
        open={openError2}
        autoHideDuration={6000}
        onClose={() => setOpenError2(false)}
      >
        <Alert
          onClose={() => setOpenError2(false)}
          severity="error"
          sx={{ width: "100%" }}
        >
          <AlertTitle>Ошибка</AlertTitle>
          {errorData}
        </Alert>
      </Snackbar>
    </div>
  );
};
