import styles from "./page.module.scss";
import { MainScreen } from "../components/home/main_screen/main_screen";
import { Possibilites } from "../components/home/possibilities/possibilites";
import { MoreInfo } from "../components/home/more_info/more_info";
import { Price } from "../components/home/price/price";
import { Contact } from "../components/home/contact/contact";
import { Feature } from "../components/home/feature/feature";
import { useEffect } from "react";

export const MainPage = () => {
  useEffect(() => {
    document.title = "Dailybot | Телеграм бот для бьюти мастеров";
  }, []);

  return (
    <main className={styles.main}>
      <MainScreen />

      <Possibilites />

      <MoreInfo />

      <Feature />

      <Price />

      <Contact />
    </main>
  );
};
