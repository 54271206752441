"use client";
import styles from "./contact.module.scss";
import { useState } from "react";
import axios from "axios";
import { Alert, AlertTitle, Snackbar } from "@mui/material";

export const Contact = () => {
    const [name, setName] = useState("");
    const [contact, setContact] = useState("");
    const [question, setQuestion] = useState("");
    const [open, setOpen] = useState(false);
    const sendOrder = async () => {
        await axios.post("https://dailybot.ru/users/question", {
            name,
            contact,
            question,
        });
        setOpen(true);
    };
    return (
        <div className={styles.contact__wrapper} id="contact">
            <div className={styles.contact}>
                <div className={styles.header}>Наши контакты</div>

                <div className={styles.description}>
                    Хотите задать вопрос или предложить улучшение?
                    <br />
                    Всегда рады Вашим обращениям!
                </div>

                <div className={styles.form}>
                    <div className={styles.title}>Написать нам</div>

                    <input
                        placeholder="Имя"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />

                    <input
                        placeholder="Телефон или Email"
                        value={contact}
                        onChange={(e) => setContact(e.target.value)}
                    />

                    <textarea
                        placeholder="Чем мы можем Вам помочь?"
                        value={question}
                        onChange={(e) => setQuestion(e.target.value)}
                    ></textarea>

                    <button onClick={() => sendOrder()}>Отправить</button>
                </div>

                <div className={styles.tg_link}>
                    <span>DailyBot в Telegram</span>
                </div>

                <img className={styles.daily} src="/images/footer-daily.png" />

                <img className={styles.daily__mobile} src="/images/daily-feature.png" />
                <Snackbar
                    open={open}
                    autoHideDuration={6000}
                    onClose={() => setOpen(false)}
                >
                    <Alert
                        onClose={() => setOpen(false)}
                        severity="success"
                        sx={{ width: "100%" }}
                    >
                        <AlertTitle>Успешно</AlertTitle>
                        Ваша заявка принята,мы свяжемся с Ваами в ближайшее время
                    </Alert>
                </Snackbar>
            </div>
            <div className={styles.contact_info}>
                ИП Дугин Артем Михайлович
                <br />
                ОГРН 314169008500100
                <br />
                info@dailybot.ru • +7 (999) 415-02-22
            </div>
        </div>
    );
};
