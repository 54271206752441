import { useEffect } from "react";
import { DUserMenu } from "../../components/usermenu/usermenu";
import { user } from "../../store/user";

export const DashboardLayout = ({ children }: { children: any }) => {
  useEffect(() => {
    user.loadCurrentUser();
  }, []);

  return (
    <div className="flex flex-col gap-3 p-1 md:p-5 max-w-full">
      <DUserMenu />
      <div>{children}</div>
    </div>
  );
};
