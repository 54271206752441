import styles from "./feature.module.scss";

export const Feature = () => {
    return (
        <div className={styles.feature__wrapper} id="feature">
            <img className={styles.arrow} src="/images/arrow.svg" />
            <div className={styles.feature}>
                <div className={styles.header}>Будущий функционал</div>
                <div className={styles.split}>
                    <div className={styles.info}>
                        <div className={styles.header__wrapper}>
                            <div className={styles.header__mobile}>Будущий функционал</div>
                            <div className={styles.subheader}>
                                ...и что будет добавлено позже.
                            </div>
                        </div>

                        <p>
                            В будущем, у Дэйли появится еще больше полезных опций. Отзывы и
                            рейтинг у мастеров, добавление салонов и выбор нескольких мастеров
                            для компаний, которые оказывают комплексные услуги. Он сможет
                            отслеживать время с последнего сеанса и предлагать записаться на
                            повторную процедуру.
                        </p>
                        <br />
                        <img className={styles.image__mobile} src="/images/feature.png" />
                        <p>
                            Также в Дэйли появится история выполненных работ и широкие
                            каталоги услуг: бот будет автоматически предлагать вашим клиентам
                            те услуги, которые чаще всего заказывают с конкретной процедурой.
                            Помимо прочего, появится функция, позволяющая выбирать себе
                            подходящего мастера и салон из списка.
                        </p>
                    </div>

                    <img src="/images/feature.png" />
                </div>

                <img className={styles.daily} src="/images/daily-feature.png" />
            </div>
        </div>
    );
};
