import { Label, Radio } from "keep-react";

type NumberFunc = (n: number) => void;

export const ThemeScreen = ({
  template,
  changeTemplate,
}: {
  template: number;
  changeTemplate: NumberFunc;
}) => {
  return (
    <div className="flex flex-row gap-3 flex-wrap md:flex-nowrap">
      <fieldset
        className="flex flex-col items-start gap-2"
        onClick={() => changeTemplate(1)}
      >
        <div className="flex flex-row gap-3">
          <Radio
            variant="circle"
            id="bd1"
            name="country"
            checked={template == 1}
          />
          <Label htmlFor="bd1">Бежевый</Label>
        </div>
        <img src="/images/beige.jpg" />
      </fieldset>

      <fieldset
        className="flex flex-col items-start gap-2"
        onClick={() => changeTemplate(2)}
      >
        <div className="flex flex-row gap-3">
          <Radio
            variant="circle"
            id="bd2"
            name="country"
            checked={template == 2}
          />
          <Label htmlFor="bd2">Синий</Label>
        </div>
        <img src="/images/blue.jpg" />
      </fieldset>

      <fieldset
        className="flex flex-col items-start gap-2"
        onClick={() => changeTemplate(3)}
      >
        <div className="flex flex-row gap-3">
          <Radio
            variant="circle"
            id="bd3"
            name="country"
            checked={template == 3}
          />
          <Label htmlFor="bd3">Лиловый</Label>
        </div>
        <img src="/images/lily.jpg" />
      </fieldset>

      <fieldset
        className="flex flex-col items-start gap-2"
        onClick={() => changeTemplate(4)}
      >
        <div className="flex flex-row gap-3">
          <Radio
            variant="circle"
            id="bd4"
            name="country"
            checked={template == 4}
          />
          <Label htmlFor="bd4">Розовый</Label>
        </div>
        <img src="/images/pink.jpg" />
      </fieldset>
    </div>
  );
};
