import { Button, Card, Steps } from "keep-react";
import { Club, DownloadSimple, Globe } from "phosphor-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { createBot } from "../../api/bot";
import { DashboardLayout } from "../../layout/dashboard/dashboard";
import { FinishScreen } from "./finish";
import { InstructionScreen } from "./instruction";
import { SecondInstructionScreen } from "./second_instruction";
import { TarifScreen } from "./tarif_screen";
import { ThemeScreen } from "./themes";

export const AddBotPage = () => {
  const [step, setStep] = useState(0);
  const [tarif, setTarif] = useState(0);
  const [template, setTemplate] = useState(0);
  const [token, setToken] = useState("");
  const [hash, setHash] = useState("");

  const createNewBot = async () => {
    const botData: any = await createBot({
      type: tarif,
      templateID: template,
      token,
    });

    setHash(botData.Hash);
  };

  const navigate = useNavigate();

  const stepClass = (itemStep: number) => {
    let cn = "";
    if (step > itemStep || step + 1 < itemStep) {
      cn += "hidden md:flex";
    }

    return cn;
  };

  const changeStep = () => {
    if (step === 4) {
      navigate("/dashboard");
    }
    setStep(step + 1);
  };

  useEffect(() => {
    if (step === 4) {
      createNewBot();
    }
  }, [step]);

  return (
    <DashboardLayout>
      <Card className="max-w-full p-3 flex flex-col justify-center items-center">
        <Steps stepType="icon" className="w-full">
          <Steps.Item
            className={`w-full ${stepClass(0)}`}
            active={step === 0}
            completed={step >= 1}
            icon={<Globe size={20} />}
            title="Тариф"
          />

          <Steps.Item
            className={`w-full ${stepClass(1)}`}
            completed={step >= 3}
            active={step >= 1 && step <= 2}
            icon={<DownloadSimple size={20} />}
            title="Создадим бота"
          />

          <Steps.Item
            className={`w-full ${stepClass(2)}`}
            completed={step >= 4}
            active={step === 3}
            icon={<DownloadSimple size={20} />}
            title="Выбор темы"
          />

          <Steps.Item
            className={`w-full ${stepClass(3)}`}
            completed={step === 5}
            active={step === 4}
            icon={<Club size={20} />}
            title="Финальные настройки"
          />
        </Steps>
        <div className="flex items-center justify-center mt-5 mb-5 max-w-full">
          {step === 0 && (
            <TarifScreen tarif={tarif} changeTarif={(t) => setTarif(t)} />
          )}
          {step === 1 && <InstructionScreen />}
          {step === 2 && (
            <SecondInstructionScreen token={token} changeToken={setToken} />
          )}
          {step === 3 && (
            <ThemeScreen template={template} changeTemplate={setTemplate} />
          )}
          {step === 4 && <FinishScreen hash={hash} />}
        </div>
        <div className="flex items-end justify-end mt-6 w-full">
          <Button
            size="sm"
            variant="outline"
            disabled={step === 0 && tarif === 0}
            onClick={changeStep}
          >
            {step === 4 ? "Готово" : "Дальше"}
          </Button>
        </div>
      </Card>
    </DashboardLayout>
  );
};
