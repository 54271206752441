import styles from "./more_info.module.scss";

export const MoreInfo = () => {
    return (
        <div className={styles.more_info__wrapper} id="moreinfo">
            <div className={styles.more_info}>
                <div className={styles.split}>
                    <img className={styles.more_info__bg} src="/images/arrow.svg" />
                    <img src="/images/about.png" />

                    <div className={styles.info}>
                        <div className={styles.header__wrapper}>
                            <div className={styles.header}>Подробнее о боте</div>
                            <div className={styles.subheader}>О том, что есть сейчас...</div>
                        </div>

                        <p className={styles.p_mobile}>
                            <img src="/images/mini-logo-text.svg" /> <span>- </span>это
                            бот-помощник, который упрощает работу специалистам сферы красоты
                            по взаимодействию с их клиентами.
                            <br />
                            <br />
                            Он позволяет настроить меню услуг, отображает текущие записи,
                            информацию о мастере; подсказывает, когда приближается следующая
                            запись, помогает выбирать и комбинировать услуги, оплачивать их
                            прямо в боте и даже вызывает такси до салона!
                            <br />
                            <br />
                            Дэйли не требует установки приложения - можно записываться прямо
                            из Telegram. Удобное решение для тех, кто хочет легко и просто
                            записаться к знакомому мастеру, не скачивая для этого целую
                            программу.
                        </p>
                    </div>
                </div>
                <div className={styles.more_info__mobile_img}>
                    <img src="/images/about.png" />
                    <img className={styles.more_info__bg} src="/images/arrow.svg" />
                </div>
                <p className={styles.p_mobile}>
                    Бот выполняет важную функцию: позволяет мастерам поднять качество
                    своего сервиса и сделать процесс записи доступнее. Так, с помощью
                    Дэйли есть возможность делать акционные рассылки своим клиентам,
                    рассказывать о новых услугах и напоминать записываться на текущие.
                    <br />
                    <br />
                    Помимо функциональных отличий, в боте есть и визуальная настройка:
                    несколько цветовых схем на выбор. Вы также можете загружать
                    собственные фотографии в качестве фона или как превью к своим услугам.
                    Воспользуйтесь двухнедельным пробным периодом{" "}
                    <img src="/images/mini-logo-text.svg" />, чтобы оценить реальную
                    пользу этого умного помощника.
                </p>
            </div>
        </div>
    );
};
