import { makeAutoObservable } from "mobx";
import { getCurrentUser } from "../api/user";

export class User {
  currentUser: any;

  constructor() {
    makeAutoObservable(this);
  }

  async loadCurrentUser() {
    const user = await getCurrentUser();
    console.log("LOAD USER", user);
    this.currentUser = user;
  }
}

export const user = new User();
