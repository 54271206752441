export const FinishScreen = ({ hash }: { hash: string }) => {
  return (
    <div className="flex flex-col">
      <h3 className="text-heading-3">Поздравляем, Ваш бот успешно создан</h3>
      <p className="text-gray-950 text-base">
        Нам осталось выполнить последние шаги
      </p>
      <br />
      <p className="text-gray-950 text-base">
        1. Вам нужнно вернуться в{" "}
        <a href="https://t.me/botfather">@botfather</a>
      </p>
      <p className="text-gray-950 text-base">
        2. Пропишите команду /setmenubutton
      </p>
      <p className="text-gray-950 text-base">
        3. У Вас появится список Ваших ботов, выберете тот который создали на
        предыдущем этапе
      </p>
      <p className="text-gray-950 text-base">
        4. Дальше Вам необходимо скопировать данную ссылку и отправить ее боту
        <pre className="language-js">
          <code className="language-js">https://bot.dailybot.ru/{hash}</code>
        </pre>
      </p>
      <p className="text-gray-950 text-base">
        5. И последний шаг, он попросит ввести имя кнопки, напишите боту "Меню"
      </p>
      <p className="text-gray-950 text-base">
        6. Готово! Мы успешно все настроили, поскольку это первый бот, Вам
        доступно 2 недели использования бота бесплатно
      </p>
    </div>
  );
};
